import { Route, Routes as Switch } from 'react-router-dom';
import { APP_HOME, APP_SUPPORT } from '@path';
import Home from '@views/Home';
import Support from '@views/Support';

const Routes = () => {
  return (
    <Switch>
      <Route path={APP_HOME} element={<Home />} />
      <Route path={APP_SUPPORT} element={<Support />} />
    </Switch>
  );
};

export default Routes;
