import React from 'react';
import Logo from '@images/logo_white.png';
import './Support.scss';

interface RouteProps {}

const Home: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <div className="support-page-container">
      <img src={Logo} alt="The Loupe Cafe" />
      <p>For all support needs please send<br/>an email to support@loupe.cafe</p>
    </div>
  );
});

export default Home;
