import React from 'react';
import LoupeLogo from '@images/logo_white.png';
import SwiggyLogo from '@images/swiggy_logo.png';
import BoologamTypo from '@images/kattravai_pattravai_typo.png';
import KattravaiTypo from '@images/boologam_aanandam_typo.png';
import BreadIllustration from '@images/bread_lof_mock_top_view.png';
import HummusIllustration from '@images/fill_hummus.png';
import PestoIllustration from '@images/fill_pesto.png';
import RedPepperIllustration from '@images/fill_red_pepper.png';
import './Home.scss';

interface RouteProps {}

const Home: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <div className="home-page-container">
      <section className="banner-box">
        <img src={HummusIllustration} alt="filling" className="fill-hummus fillers ease-element" />
        <img src={PestoIllustration} alt="filling" className="fill-pesto fillers ease-element" />
        <img src={RedPepperIllustration} alt="filling" className="fill-pepper fillers ease-element" />
        <div className="text-wrapper">
          <h1>
            Pure <span className="subtle">ingredients</span>
          </h1>
          <h1>
            Carefully crafted <span className="subtle">with love</span>
          </h1>
          <h1>Kid-approved</h1>
          <h1>Gut-friendly</h1>
          <h1>
            Ethically <span className="subtle">made</span>
          </h1>
        </div>
        <div className="logo-wrapper">
          <img src={LoupeLogo} alt="Loupe" className="app-logo" />
        </div>
      </section>
      <section className="partner-box">
        <p>Now available on</p>
        <ul>
          <li>
            <a
              href="https://www.swiggy.com/direct/brand/528802?source=swiggy-direct&subSource=generic"
              title="Loupe on Swiggy"
              target="_blank"
            >
              <img src={SwiggyLogo} alt="Swiggy" className="partner-logo" />
            </a>
          </li>
        </ul>
      </section>
      <section className="feedback-box">
        <img src={BreadIllustration} alt="Bread" className="bread-loaf ease-element" />
        <div className="drama-wrapper">
          <h1>Loupe</h1>
          <p>
            A contemporary cafe with a big heart and a commitment to great taste. We're here to offer food that you can
            enjoy every day. No ready-made shortcuts – just fresh, delicious meals made with care.
          </p>
        </div>
        <p>
          Dive into our delicious food, but don’t stop there - throw your feedback our way! We’re all ears and super
          committed to taking every thought seriously. If most of you don’t love something, we’ll even consider ditching
          it from the menu. We believe the world should be more interactive, and we’ve got all the patience to squeeze
          the juice out of your feedback. We’re listening loud and clear!
        </p>
        <a href="https://feedback.loupe.cafe" className="feedback-link ease-element">
          I have some feedback
        </a>
      </section>
      <section className="typo-box">
        <img src={BoologamTypo} alt="Boologam Aanandhathin Ellai" className="boologam-item" />
        <img src={KattravaiTypo} alt="Kattravai Pattravai" className="kattravai-item" />
      </section>
    </div>
  );
});

export default Home;
